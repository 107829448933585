@tailwind base;

@tailwind components;

@tailwind utilities;

@import url("./fonts/fontCatamaran.css");
@import url("./fonts/fontGordita.css");

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(../src/fonts/Montserrat/Montserrat-Bold.ttf) format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(../src/fonts/Montserrat/Montserrat-SemiBold.ttf) format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(../src/fonts/Montserrat/Montserrat-Medium.ttf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(../src/fonts/Montserrat/Montserrat-Regular.ttf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(../src/fonts/Montserrat/Montserrat-Light.ttf) format("opentype");
  font-weight: 300;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden; /* Esto evitará la barra de desplazamiento horizontal */
  padding: 0;
  margin: 0;
  font-family: 'Catamaran', sans-serif; 
  color: #454545;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

div.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.521);
  backdrop-filter: blur(5px);
}

/* h1 { margin: 40px 0 20px 0; width: 100%; text-align: center; } */
.container { display: flex; justify-content: center; }